'use client'

import { m } from 'framer-motion'
// @mui
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
// layouts
// routes
// components
import { Stack } from '@mui/material'
import Iconify from 'src/components/iconify/iconify'
import { useRouter } from 'src/routes/hooks/use-router'
import { RouterLink } from '../../routes/components'
import { MotionContainer, varBounce } from '../../components/animate'
// assets
import { ForbiddenIllustration } from '../../assets/illustrations'

// ----------------------------------------------------------------------

type Props = {
  title?: string
  subtitle?: string
}

export default function UnauthorizedView(props: Props): React.ReactNode {
  const { title, subtitle } = props

  const router = useRouter()

  return (
    <MotionContainer>
      <m.div variants={varBounce().in}>
        <Typography variant="h3" sx={{ mb: 2 }}>
          {title || 'Sorry, Page Not Found!'}
        </Typography>
      </m.div>

      <m.div variants={varBounce().in}>
        <Typography sx={{ color: 'text.secondary' }}>
          {subtitle ||
            'Sorry, we couldn’t find the page you’re looking for. Perhaps you’ve mistyped the URL? Be sure to check your spelling.'}
        </Typography>
      </m.div>

      <m.div variants={varBounce().in}>
        <ForbiddenIllustration
          sx={{
            height: 260,
            my: { xs: 2, sm: 5 },
          }}
        />
      </m.div>
      <Stack spacing={2} direction="row">
        <Button
          onClick={() => {
            router.back()
          }}
          size="large"
          color="primary"
          variant="contained"
          startIcon={<Iconify icon="ic:round-arrow-back-ios" />}
        >
          Go back
        </Button>
        <Button component={RouterLink} href="/home" size="large" color="primary" variant="outlined">
          Go home
        </Button>
      </Stack>
    </MotionContainer>
  )
}
