// @mui
import Stack from '@mui/material/Stack'
import Container from '@mui/material/Container'
//
import React, { useContext } from 'react'
import { IntegrationsContext } from 'src/providers/integrations'
import { HeaderSimple as Header } from '../_common'

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode
  py?: number
}

export default function CompactLayout({ children, py }: Props): React.ReactNode {
  const integrationsContext = useContext(IntegrationsContext)
  const { appType } = integrationsContext

  return (
    <>
      {appType === 'web_app' && <Header />}

      <Container component="main">
        <Stack
          sx={{
            py: py || 12,
            mt: 10,
            m: 'auto',
            minHeight: '100vh',
            justifyContent: 'top',
          }}
        >
          {children}
        </Stack>
      </Container>
    </>
  )
}
